@import './styles/utils/normalize';
@import './styles/utils/mixins';
@import './styles/utils/extends';
@import './styles/utils/vars';
@import './styles/utils/normalize';
@import './styles/fonts/mont.scss';
@import './styles/fonts/typography.scss';

@import './styles/blocks/container.scss';
@import './styles/blocks/grid.scss';
@import './styles/blocks/icons.scss';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $hover-color;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

::selection{
  background-color: #f8670080;
  color: $white-color;
}
