.capacity {
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e2e6e9;

  &__container {
    display: flex;
    align-items: center;
  }

  &__link {
    text-decoration: none;
    color: #0F0F11;
  }

  &__button {
    margin: 8px 8px 0 0;
    padding: 4px 8px;

    text-decoration: none;

    border: 1px solid #B4BDC3;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
      border: 1px solid #0F0F11;
    }

    &--active {
      color: #fff;
      background-color: #0F0F11;
    }
  }
}
