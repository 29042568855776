@import '../../App.scss';

.catalog {
  &__title {
    margin-top: 24px;

    @include onTablet {
      margin-top: 40px;
    }
  }

  &__back {
    margin: 0;
    margin-top: 8px;
    color: $secondary-color;
    @include body-text;
    cursor: pointer;
  }

  &__sorting {
    margin: 0;
    margin-top: 40px;
  }

  &__filter {
    &-title {
      margin: 0;
      color: $secondary-color;
      font-weight: 700;
      @include small-text;
    }

    &-sort-by {
      margin-top: 4px;
      height: 40px;
      width: 100%;
      border: 1px solid $icons-color;
      border-radius: 8px;
      padding: 0 8px;
      color: $primary-color;
      @include button-text;
      appearance: none;
      background-color: transparent;
      outline: none;

      &:hover {
        border-color: $secondary-color;
      }

      &:focus {
        outline: none;
        border-color: $primary-color;
      }
    }
  }

  &__item-count {
    margin: 0;
    margin-top: 8px;
    color: $secondary-color;
    @include body-text;
  }

  &__phones {
    margin-top: 24px;
  }
}
