@import '../../App.scss';

.button-addToCart {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
  color: $white-color;
  background: $accent-color;

  cursor: pointer;

  &:hover {
    box-shadow: 0px 3px 13px rgba(23, 32, 49, 0.4);
  }

  &-checked {
    border: 1px solid #e2e6e9;
    color: #f86800;
    background: none;
  }
}
