@import '../../App.scss';

.header {
  position: sticky;
  top: 0;
  z-index: 2;

  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white-color;
  opacity: 1;

  font-style: normal;
  font-weight: 700;
  font-size: 12px;

  letter-spacing: 0.04em;

  box-shadow: 0px 1px 0px #e2e6e9;

  &-logo {
    display: flex;
    margin-left: 16px;

    @include onDesktop {
      margin-left: 24px;
    }
  }

  &-menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: $white-color;
    border: none;
    box-shadow: -1px 0px 0px $elements-color;
  }
}

.menu__nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav {

  &-list {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
  }

  &-item {
    display: flex;

    @include onTablet {
      margin-right: 32px;
    }

    @include onDesktop {
      margin-right: 32px;
    }

    &:last-child {
      margin: 0;
    }
  }

  &-link {
    position: relative;
    @include uppercased-text;
    text-decoration: none;
    color: $secondary-color;

    @include onTablet {
      padding: 19px 0px;
    }

    @include onDesktop {
      padding: 26px 0px;
    }

    &:hover {
      color: $primary-color;
    }

    &--active {
      color: $primary-color;

      &::after {
        display: block;
        position: absolute;
        content: '';
        height: 4px;
        width: 100%;
        bottom: 0;
        margin-bottom: 0;
        background-color: $primary-color;
      }
    }
  }
}

.icon {
  position: relative;
  display: flex;

  padding: 16px;

  text-decoration: none;

  background-color: #fff;

  box-shadow: -1px 0px 0px #e2e6e9;

  @include onDesktop {
    padding: 24px;
  }

  &-container {
    position: relative;
    display: flex;
    align-items: center;
  }

  &-sign-in {
    width: 16px;
    height: 16px;
  }

  &--active {
    color: $primary-color;

    &::after {
      display: block;
      position: absolute;
      content: '';
      height: 4px;
      width: 100%;
      right: 0;
      bottom: 0;
      margin-bottom: 0;
      background-color: $primary-color;
    }
  }
}
