.grid {
  display: grid;
  column-gap: 16px;

  @include onPhone {
    grid-template-columns: repeat(4, 1fr);
  }

  @include onTablet {
    grid-template-columns: repeat(12, 1fr);
  }

  @include onDesktop {
    grid-template-columns: repeat(24, 1fr);
  }

  &--catalog {
    column-gap: 16px;
    row-gap: 40px;

    @include onPhone {
      grid-template-columns: repeat(1, 1fr);
    }

    @include onTablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 910px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include onDesktop {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &--phone {
    display: block;

    @include onPhone {
      display: grid;
    }
  }

  &--tablet {
    display: block;

    @include onTablet {
      display: grid;
    }
  }

  &--desktop {
    display: block;

    @include onDesktop {
      display: grid;
    }
  }

  &--deskAndTablet {
    display: block;

    @include onTablet {
      display: grid;
    }

    @include onDesktop {
      display: grid;
    }
  }

  &__item {
    &--phone {
      @include onPhone {
        @for $start from 1 through 4 {
          @for $end from $start through 4 {
            &-#{$start}-#{$end} {
              grid-column: #{$start} / #{$end + 1};
            }
          }
        }
      }
    }

    &--tablet {
      @include onTablet {
        @for $start from 1 through 12 {
          @for $end from $start through 12 {
            &-#{$start}-#{$end} {
              grid-column: #{$start} / #{$end + 1};
            }
          }
        }
      }
    }

    &--desktop {
      @include onDesktop {
        @for $start from 1 through 24 {
          @for $end from $start through 24 {
            &-#{$start}-#{$end} {
              grid-column: #{$start} / #{$end + 1};
            }
          }
        }
      }
    }
  }
}
