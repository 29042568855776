.image__container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 40px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
}

.text {
  margin-top: 32px;
  text-align: center;
}
