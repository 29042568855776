@import '../../App.scss';

.footer {
  align-items: left;
  padding: 32px 16px;
  gap: 32px;
  background-color: $white-color;
  box-shadow: 0px -1px 0px #e2e6e9;
  box-sizing: border-box;
  font-weight: 600;
  margin-top: auto;

  &__container {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: flex-start;
    gap: 32px;

    @include onTablet {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0;
    }
  }

  :hover {
    color: $primary-color;
  }

  &__logo {
    width: 89px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @include onTablet {
      flex: 1;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    gap: 16px;
    @include uppercased-text;

    @include onTablet {
      flex-direction: row;
      align-items: center;
      height: 100%;
      flex: 1;
    }
  }

  &__link {
    text-decoration: none;
    color: $icons-color;
    background: none;
    border: none;
    cursor: pointer;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    gap: 16px;
    color: $secondary-color;
    @include small-text;
    margin: 0 auto;

    @include onTablet {
      flex-direction: row;
      flex: 1;
      justify-content: flex-end;
    }

    &--icon {
      @include icon-wrapper-default;
      background: none;
      cursor: pointer;

      &:hover {
        border-color: $primary-color;
      }
    }
  }

  @include onTablet {
    flex-direction: row;
    justify-content: center;
    padding: 32px 32px;
    gap: 0;
  }
}
