@import '../../App.scss';

.back-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $secondary-color;
  margin-top: 25px;
  width: 80px;
  
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  
  :hover {
    color: $primary-color;
  }

  &__icon {
    margin-right: 4px;
  }

  &__text {
    height: 18px;
  }

  @include onTablet {
    margin-top: 40px;
  }
}
