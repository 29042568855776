.tech-specs {
  margin-top: 56px;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 32px;
  }

  &__title {
    padding-bottom: 16px;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;

    color: #0f0f11;

    border-bottom: 1px solid #e2e6e9;
  }

  &__name {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 23px;
  }

  &__description {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 23px;
    text-align: right;
  }

  &__item {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}
