@import '../../App.scss';

.cart-Page {
  padding: 25px 0 56px;

  &__title {
    margin: 24px 0 32px;

    @include onTablet {
      margin: 16px 0 32px;
    }
  }

  &__listOfCards {
    display: flex;
    gap: 16px;
    flex-direction: column;

    margin-bottom: 32px;
  }
}
