@import '../../app.scss';

.slider {
  position: relative;
  width: 100%;
  margin: 0 auto 66px;
  padding: 0;
  height: 400px;

  @include onTablet {
    margin-bottom: 74px;
    padding: 0 50px;
  }

  @include onDesktop {
    margin-bottom: 90px;
    padding: 0 50px;
  }

  & .swiper-slide {
    width: 100%;

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  & .swiper-custom-pagination {
    display: flex;
    justify-content: center;
    gap: 14px;
    width: 100%;

    & .swiper-pagination-bullet {
      position: relative;
      bottom: -18px;
      width: 14px;
      height: 4px;
      border-radius: 5px;
      background-color: $primary-color;
    }
  }

  & .swiper {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
  }

  & .arrow-left {
    @include sliderArrows;
    left: 0;
  }

  & .arrow-right {
    @include sliderArrows;
    right: 0;
  }
}
