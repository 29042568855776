@import '../../App.scss';

.item__card {
  &-title {
    margin: 16px 0 32px;

    @include onTablet {
      margin: 16px 0 40px;
    }
  }
}

.phone__image-wraper {
  margin-top: 40px;

  @include onTablet {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @include onDesktop {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.controllers {
  margin-top: 40px;
}

.add-to-cart {
  display: flex;
  justify-content: space-between;
  text-align: center;
  gap: 8px;
  margin-top: 16px;

  &__button {
    width: 230px;
    height: 48px;

    @include onTablet {
      width: 180px;
    }

    @include onDesktop {
      width: 263px;
    }
  }

  &__favourites {
    width: 48px;
    height: 48px;
  }
}

.recomended {
  margin: 56px 0px;

  &__title {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 140%;

    @include onTablet {
      margin: 64px 0px;
      font-size: 32px;
      line-height: 41px;
      letter-spacing: -0.01em;
    }

    @include onDesktop {
      margin: 80px 0px;
      font-size: 32px;
      line-height: 41px;
      letter-spacing: -0.01em;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
