@import '../../App.scss';

.dropdown {
  margin-top: 4px;
  position: relative;
  height: 40px;
  width: 100%;
  border: 1px solid $icons-color;
  border-radius: 8px;
  color: $primary-color;
  @include body-text;

  &:hover {
    border-color: $secondary-color;
  }

  &--open {
    border-color: $primary-color;

    &:hover {
      border-color: $primary-color;
    }
  }

  &:focus {
    border-color: $primary-color;
  }

  &__toggle {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &-text {
      margin-left: 8px;
    }

    &-icon {
      margin-right: 8px;
    }
  }

  &__menu {
    display: flex;
    width: 100%;
    top: 100%;
    left: 0;
    padding: 0;
    margin-top: 4px;
    position: absolute;
    flex-direction: column;
    background-color: $white-color;
    border: 1px solid $elements-color;
    border-radius: 8px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    z-index: 1;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 32px;
    z-index: 1;
    color: $secondary-color;
    background-color: $white-color;
    border-radius: 8px;
    cursor: pointer;
    list-style: none;

    &:hover {
      color: $primary-color;
      background-color: $hover-color;
    }
  }
}
