@font-face {
  font-family: 'Mont';
  src: url('./Mont-Bold.otf');
  font-weight: 800;
}

@font-face {
  font-family: 'Mont';
  src: url('./Mont-SemiBold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Mont';
  src: url('./Mont-Regular.otf');
  font-weight: 600;
}
