@import '../../App.scss';

.phone__image {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--main {
    max-height: 288px;
    max-width: 228px;

    @include onTablet {
      max-height: 440px;
      max-width: 288px;
    }

    @include onDesktop {
      max-height: 464px;
      max-width: 376px;
    }
  }
}
