@import '../../../App.scss';

.breadcrumbsItem {
  display: flex;
  align-items: center;

  &-icon {
    margin: 0 8px;
  }

  &-link {
    height: 12px;
  }
}
 