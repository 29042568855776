* {
  box-sizing: border-box;
}

h1,
h2,
ul,
p {
  margin: 0;
}
