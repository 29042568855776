@import '../../App.scss';

.home-page {
  margin: 24px auto 64px;

  @include onTablet {
    margin: 32px auto;
  }

  @include onDesktop {
    margin: 56px auto 80px;
  }

  &__title {
    margin: 24px 0;
  
    @include onTablet {
      margin: 32px 0;
    }
  
    @include onDesktop {
      margin: 56px 0;
    }
  }
}

.sliderign {
  max-width: 100%;
  height: auto;
  object-fit: cover
}

.alice-carousel {

  &__dots {

    &-item {
      height: 10px;
      width: 10px;
      border: solid 1px #111;
    }
  }
}

