@import '../../App.scss';

.shop-by-category {
  margin: 54px auto 56px;

  @include onTablet {
    margin: 64px auto 64px;
  }

  @include onDesktop {
    margin: 80px auto 80px;
  }

  &__wrapper {
    margin: 24px auto 0;
  }

  &__item {
    margin: 0 auto 24px;
    text-decoration: none;

    &:last-child {
      margin-bottom: 0;
    }

    @include onTablet {
      margin: 0;
    }
  }

  &__photo {
    max-width: 100%;
    height: auto;
    transform: scale(1.03);

    transition: 0.3s ease-in-out;

    &:hover {
      @include onTablet {
        transform: scale(1.2);
      }
    }

    &-wrapper {
      overflow: hidden;
      border-radius: 8px;
    }
  }

  &__card-title {
    margin: 24px 0 8px;
    color: $primary-color;
    @include desktop-tablet-h4;
  }

  &__total {
    color: $secondary-color;
    @include body-text;
  }
}
