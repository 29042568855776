@import '../../../App.scss';

.totalPrice {
  padding: 24px;
  border: 1px solid $elements-color;
  border-radius: 16px;
  max-height: 190px;

  &__title {
    color: $primary-color;

    font-weight: 800;
    font-size: 32px;
    line-height: 41px;
    text-align: center;

    letter-spacing: -0.01em;
  }

  &__count {
    color: $secondary-color;

    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;

    margin-bottom: 16px;
  }

  &__hr {
    background-color: $elements-color;
    height: 1px;

    margin-bottom: 16px;
  }

  &__button {
    display: block;
    width: 100%;
    height: 48px;
    cursor: pointer;

    border: 1px solid $accent-color;
    border-radius: 8px;

    color: $white-color;
    background: $accent-color;

    font-weight: 700;
    font-size: 14px;
    line-height: 48px;
    text-align: center;
    transition: 0.15s;

    @include onTablet {
      &:hover {
        box-shadow: 0px 3px 13px rgba(23, 32, 49, 0.4);
      }

      &:active {
        color: $accent-color;
        border: 1px solid $elements-color;
        background-color: transparent;
        box-shadow: none;
      }
    }

    &--disabled {
      cursor: not-allowed;
      box-shadow: none;
      border: 1px solid $elements-color;
      color: $elements-color;
      background-color: $white-color;

      @include onTablet {
        &:active {
          cursor: not-allowed;
          box-shadow: none;
          border: 1px solid $elements-color;
          color: $elements-color;
          background-color: $white-color;
        }

        &:hover {
          cursor: not-allowed;
          box-shadow: none;
          border: 1px solid $elements-color;
          color: $elements-color;
          background-color: $white-color;
        }
      }
    }
  }
}
