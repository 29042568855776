@import '../../App.scss';

.menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: $white-color;
  z-index: 2;
  opacity: 0;
  animation: fade-in 0.5s forwards;

  &::-webkit-scrollbar {
    display: none;
  }

  @include onTablet {
    display: none;
  }

  &__content {
    overflow-y: scroll;
    max-height: 100vh;
  }

  &__header {
    display: flex;
    width: 100%;
    height: 48px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 0 $elements-color;
  
    &-logo {
      display: flex;
      margin-left: 16px;
  
    @include onDesktop {
      margin-left: 24px;
    }
  }

    &__close {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      background-color: $white-color;
      border: none;
      box-shadow: -1px 0px 0px $elements-color;
    }
  }

  &__icons {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100%;
    height: 64px;
    justify-content: space-around;
    align-items: center;
    bottom: 0;
    border-top: 1px solid $elements-color;
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;

  &__list--burger {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0;

    list-style: none;
  }

  &__link {
  
    &--burger {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: 32px;
      padding-bottom: 8px;

      @include uppercased-text;

      text-decoration: none;
      color: $secondary-color;
    
      &:hover {
        color: $primary-color;
      }
    }

    &--active {
      color: $primary-color;

      &::after {
        display: block;
        position: absolute;
        content: '';
        height: 2px;
        bottom: 0;
        width: 100%;
        background-color: $primary-color;
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.icons {

  &__favorite,
  &__cart {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &:not(:last-child) {
      border-right: 1px solid $elements-color;
    }
  }
}
