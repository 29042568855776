@import '../../../App.scss';
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);

.image__wrapper {
  display: flex;
  justify-content: center;
}

.teamCard {
  font-family: 'Mont', sans-serif;
  display: flex;
  flex-direction: column;
  padding: 32px 24px 24px;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;

  &__photo {
    align-self: center;
    margin-bottom: 24px;
    max-width: 150px;
    max-height: 150px;
    border-radius: 50%;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.2);
      border-radius: 40%;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    }
  }

  &__name {
    font-size: 22px;
    text-align: center;
  }

  &__position {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
    text-align: center;
  }

  &__divider {
    width: 100%;
    border: 1px solid $elements-color;
    margin: 16px 0px;
  }

  &__links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &-social {
      width: 30px;
      height: 30px;
      border: 1px solid #ddd;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666;
      border-radius: 50%;
      transition: all 0.3s;
      text-decoration: none;
    }
  }
}

.phone {

  &:hover {
    @include onTablet {
      transform: scale(1.2);
      color: $white-color;
      background-color: green;
    }
  }
}

.email {
  &:hover {
    @include onTablet {
      transform: scale(1.2);
      color: $white-color;
      background-color: #dd4b39;
    }
  }
}

.linkedin {
  &:hover {
    @include onTablet {
      transform: scale(1.2);
      color: $white-color;
      background-color: #0077b5;
    }
  }
}

.github {
  &:hover {
    @include onTablet {
      transform: scale(1.2);
      color: $white-color;
      background-color: #333;
    }
  }
}
