@import '../../App.scss';
.button-AddToFavourites {
  border: 1px solid $icons-color;
  border-radius: 48px;
  width: 40px;
  height: 40px;
  margin: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-size: 15px 14px;

  &:hover {
    border: 1px solid $primary-color;
    cursor: pointer;
  }

  &-default {
    background-image: url(../../images/favourites_icon.svg);
  }

  &-checked {
    background-image: url(../../images/favourites_icon_blue.svg);
  }
}
