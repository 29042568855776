@import '../../../App.scss';

.cardOfCart {
  $origin: #{&};

  display: flex;
  flex-direction: column;
  gap: 16px;

  @include onTablet {
    flex-direction: row;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
  }

  background-color: $white-color;
  border: 1px solid $elements-color;
  border-radius: 16px;
  padding: 16px;

  &__header {
    display: flex;
    gap: 23px;
    justify-content: space-between;
    align-items: center;
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    @include onTablet {
      gap: 24px;
    }
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    cursor: pointer;

    width: 32px;
    height: 74px;

    border: none;
    background-color: Transparent;

    transition: 0.15s;

    &--border {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;

      width: 32px;
      height: 32px;
      border: 1px solid #ffffff00;

      border-radius: 50%;
    }

    @include onTablet {
      &:hover {
        .cardOfCart__close--border {
          border: 1px solid $icons-color;
        }

        .iconSvg * {
          fill: $primary-color;
        }
      }

      &:active {
        .cardOfCart__close--border {
          border: 1px solid $accent-color;
        }
      }
    }
  }

  &__photo {
    width: 80px;
    height: 80px;

    object-position: center;
    object-fit: contain;

    transition: 0.15s;
  }

  &__title {
    color: $primary-color;

    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  &__counter {
  }

  &__price {
    color: $primary-color;

    width: 80px;

    font-weight: 800;
    font-size: 22px;
    line-height: 140%;
    text-align: right;
  }

  transition: box-shadow 0.3s ease-in-out;

  @include onTablet {
    &:hover {
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);

      #{$origin}__photo {
        transform: scale(1.15);
      }
    }
  }
}

.counter {
  display: flex;
  align-items: center;

  &__amount {
    width: 32px;
    height: 32px;

    color: $primary-color;
    text-align: center;
    line-height: 32px;
  }

  &__button {
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 32px;

    border: 1px solid $elements-color;
    transition: 0.15s;

    &--active {
      cursor: pointer;
      border: 1px solid $icons-color;
      .iconSvg * {
        fill: $primary-color;
        transition: 0.15s;
      }

      @include onTablet {
        &:hover {
          border: 1px solid $primary-color;
        }

        &:active {
          .iconSvg * {
            fill: $accent-color;
            transition: 0.15s;
          }
        }
      }
    }

    border-radius: 50%;
    background-color: Transparent;
  }
}
