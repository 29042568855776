@import '../../App.scss';

.page-title {
  font-weight: 800;
  @include mobile-h1;
  line-height: 100%;

  @include onTablet {
    @include desktop-tablet-h1;
  }
}
