@import '../../App.scss';

.item__card__link {
  text-decoration: none;

  &:visited {
    text-decoration: none;
  }
}

.image__wrapper {
  display: flex;
  justify-content: center;
}

.card {
  display: inline-block;
  box-sizing: border-box;
  max-width: 272px;
  width: 100%;
  min-height: 100%;
  background: $white-color;
  font-family: 'Mont', sans-serif;
  font-style: normal;
  color: $primary-color;
  border: 1px solid $elements-color;
  border-radius: 8px;
  margin: 0 auto;
  transition: box-shadow 0.3s ease-in-out;


  &:hover {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: 32px 24px 24px;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
  }

  &__photo {
    align-self: center;
    margin-bottom: 24px;
    width: 146px;
    height: 196px;
    transition: transform 0.3s ease-in-out;
  }

  &:hover .card__photo {
    @include onTablet {
      transform: scale(1.1);
    }
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    height: 35px;
    margin-bottom: 8px;

    color: #0f0f11;
  }

  &__price {
    font-size: 22px;
    line-height: 140%;
    margin-bottom: 8px;

    &-current {
      font-weight: 800;
      margin-right: 8px;

      color: #0f0f11;
    }

    &-full {
      font-weight: 600;
      color: $secondary-color;
      text-decoration-line: line-through;
    }
  }

  &__divider {
    width: 100%;
    border: 1px solid $elements-color;
    margin-bottom: 16px;
  }

  &__specs {
    font-weight: 700;
    font-size: 12px;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 8px;
  }

  &__spec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;

    &-title {
      color: $secondary-color;
    }

    &-value {
      color: $primary-color;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;

    &-cart {
      height: 40px;
      width: 175px;
    }

    &-favourites {
      height: 40px;
      width: 40px;
    }
  }
}
