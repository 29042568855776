.colors {
  padding-bottom: 24px;
  border-bottom: 1px solid #e2e6e9;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__circle {
    margin-right: 8px;

    &-item {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 2px solid #fff;
      cursor: pointer;
      padding: 1px;

      box-shadow: 0px 0px 0px 1px #e2e6e9;

      &:hover {
        box-shadow: 0px 0px 0px 1px #B4BDC3;
      }

      &--active {
        box-shadow: 0px 0px 0px 1px black;
      }
    }

    &-container {
      display: flex;
      align-items: center;

      margin-top: 8px;
    }
  }
}
