$accent-color: #f86800;
$secondary-accent-color: #476df4;
$primary-color: #0f0f11;
$secondary-color: #89939a;
$icons-color: #b4bdc3;
$elements-color: #e2e6e9;
$hover-color: #fafbfc;
$white-color: #ffffff;
$green-color: #27ae60;
$red-color: #eb5757;
$arrow-color: #b4bdc4;
$transition-time: 0.3s;
