@import '../../../App.scss';

.recomend {
  &-models {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
    overflow: hidden;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 8px;

      &-thumb {
        background-color: transparent;
      }
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    &-buttons {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }
}
